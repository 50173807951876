import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import React, { useCallback, useContext, useReducer } from 'react';
import config from './config';
import TenantContext from './Context';
import { IMessage } from 'types/models/IMessage';
import { reducer } from './reducer';
import { Logger } from 'aws-amplify';
import { UMTenantItem, UMTenantUserItem } from '@voicefoundry-cloud/vf-omp-shared';
import {
  useInfoViewActionsContext,
  fetchStart,
  fetchSuccess,
  fetchError,
} from 'shared/components/InfoView/InfoViewContext';
import { useAppContext } from '@vf/utility/ContextProvider';
export const INITIAL_STATE = {
  ...config,
};

const logger = new Logger('TenantContextProvider', 'INFO');

const TenantContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  logger.debug(`Initializing Provider`);
  const { user, setTenancySetting } = useAppContext();
  const infoDispatch = useInfoViewActionsContext()!;

  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  // useEffect(() => {
  //   fetchCognitoUsers()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [api]);

  const loadAdminTenantData = useCallback(() => {
    Promise.all([fetchAppConfig(), fetchAvailableTenants(), fetchCurrentTenant()]).then(() =>
      dispatch({ type: 'FETCHING_SUCCESS' })
    );
  }, []);
  const loadTenantData = useCallback(() => {
    Promise.all([fetchAvailableTenants(), fetchCurrentTenant()]).then(() => dispatch({ type: 'FETCHING_SUCCESS' }));
  }, []);

  const fetchAvailableTenants = async () => {
    dispatch({ type: 'FETCHING_DATA' });
    try {
      const tenants = await api.userManagement.getTenants();
      dispatch({ type: 'SET_AVAILABLE_TENANTS', payload: tenants });
    } catch (err) {
      dispatch({
        type: 'FETCHING_ERROR',
        payload: {
          type: 'error',
          message: 'Error fetching Available Tenants',
        },
      });
    }
  };
  const fetchCurrentTenant = async () => {
    dispatch({ type: 'FETCHING_DATA' });
    try {
      const tenant = await api.userManagement.getUserPrimaryTenant();
      dispatch({ type: 'SET_CURRENT_TENANT', payload: tenant });
    } catch (err) {
      dispatch({
        type: 'FETCHING_ERROR',
        payload: {
          type: 'error',
          message: 'Error fetching Current Tenant',
        },
      });
    }
  };
  const upsertTenantUsers = async (tenantKey: string, tenantUsers: UMTenantUserItem[]): Promise<void> => {
    dispatch({ type: 'FETCHING_DATA' });
    try {
      logger.info('uploading', tenantUsers);
      const results = await Promise.all(
        tenantUsers.map(async tenantUser => await api.userManagement.upsertTenantUser(tenantUser))
      );
      logger.info('RESULTS ', results);
    } catch (err) {
      dispatch({
        type: 'FETCHING_ERROR',
        payload: {
          type: 'error',
          message: 'Error associating users to role',
        },
      });
    }
  };
  const switchCurrentTenant = async (tenant: UMTenantItem) => {
    infoDispatch(fetchStart());
    try {
      const adminPrimaryTenantUser = new UMTenantUserItem(tenant.key, user.username, true);
      const { ok } = await api.userManagement.upsertTenantUser(adminPrimaryTenantUser);
      dispatch({ type: 'SET_CURRENT_TENANT', payload: tenant });
      infoDispatch(fetchSuccess('Successfully switched tenant'));
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };

  const fetchAppConfig = async () => {
    dispatch({ type: 'FETCHING_DATA' });
    try {
      console.log('fetching app config');
      const appConfig = await api.userManagement.getAppConfig();
      console.log('CONFIG ', appConfig);
      setTenancySetting(appConfig.tenancyOn);
      //dispatch({type: 'SET_CURRENT_TENANT', payload: tenants[0]});
    } catch (err) {
      console.log(err);
      dispatch({
        type: 'FETCHING_ERROR',
        payload: {
          type: 'error',
          message: 'Error fetching App Config',
        },
      });
    }
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { msg } });
  };
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    setMsg(null);
  };

  return (
    <TenantContext.Provider
      value={{
        ...state,
        loadTenantData,
        loadAdminTenantData,
        switchCurrentTenant,
        handleClose,
      }}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantContextProvider;
