import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { iNavItemProps } from 'modules/AppRoutes';

const HolidaysPage = lazy(() => import('./Pages/HolidaysPage'));
const ClosuresPage = lazy(() => import('./Pages/ClosuresPage'));
const HoursPage = lazy(() => import('./Pages/HoursPage'));
const ConfigSetPage = lazy(() => import('./Pages/ConfigSetPage'));
const MappingSyncPage = lazy(() => import('./Pages/MappingSyncPage'));
const ContactFlowSyncPage = lazy(() => import('./Pages/ContactFlowSyncPage'));
const ConfigSetSyncPage = lazy(() => import('./Pages/ConfigSetSyncPage'));
const LandingPage = lazy(() => import('./Pages/Landing'));
const MenuManagementPage = lazy(() => import('./Pages/MenuManagementPage'));
const ContactEntryPointManagementPage = lazy(() => import('./Pages/ContactEntryPointManagementPage'));
const QueueTreatmentPage = lazy(() => import('./Pages/QueueTreatmentPage'));
const PermissionsManagementPage = lazy(() => import('./Pages/PermissionsManagementPage'));
const UserManagementPage = lazy(() => import('./Pages/Connect/UserManagementPage'));
const RealtimeMetricsPage = lazy(() => import('./Pages/Connect/RealtimeMetricsPage'));
const ContactSearchPage = lazy(() => import('./Pages/Connect/ContactSearchPage'));
const PhoneNumberTaxonomyPage = lazy(() => import('./Pages/TaxonomyPage'));
const ExtensionDialingPage = lazy(() => import('./Pages/ExtensionDialingPage'));

export const AdminRoutes: iNavItemProps[] = [
  {
    id: 'omp-landing-page',
    auth: [],
    title: 'OMP Home',
    messageId: 'OMP Home',
    type: 'item',
    url: '/',
    route: <Route key="omp-page-route-/admin-home" path="/" element={<LandingPage />} />,
  },
  {
    id: 'calendar-management',
    auth: ['calendarManagement', 'holidaysManagement', 'hoursOfOperationManagement', 'closuresManagement'],
    title: 'Calendar Management',
    messageId: 'Calendar Management',
    type: 'collapse',
    icon: 'event',
    children: [
      {
        id: 'holiday-management',
        auth: ['calendarManagement', 'holidaysManagement'],
        title: 'Holiday Management',
        messageId: 'Holidays',
        type: 'item',
        route: (
          <Route
            key="omp-page-route-/calendar-management/holidays"
            path="/calendar-management/holidays"
            element={<HolidaysPage />}
          />
        ),
        url: '/calendar-management/holidays',
      },
      {
        id: 'hours-management',
        auth: ['calendarManagement', 'hoursOfOperationManagement'],
        title: 'Hours Management',
        messageId: 'Hours',
        type: 'item',
        route: (
          <Route
            key="omp-page-route-/calendar-management/hours"
            path="/calendar-management/hours"
            element={<HoursPage />}
          />
        ),
        url: '/calendar-management/hours',
      },
      {
        id: 'closures-management',
        auth: ['calendarManagement', 'closuresManagement'],
        title: 'Closures Management',
        messageId: 'Closures',
        type: 'item',
        route: (
          <Route
            key="omp-page-route-/calendar-management/closures"
            path="/calendar-management/closures"
            element={<ClosuresPage />}
          />
        ),
        url: '/calendar-management/closures',
      },
    ],
    url: '/calendar-management',
  },
  {
    id: 'config-set-management',
    auth: ['configSetManagement'],
    title: 'Configuration Set',
    messageId: 'Configuration Set',
    type: 'item',
    url: '/contact-flows',
    icon: 'settings',
    route: <Route key="omp-page-route-/contact-flows" path="/contact-flows" element={<ConfigSetPage />} />,
  },
  {
    id: 'environment-sync-management',
    auth: ['syncManagement'],
    title: 'Environment Sync',
    messageId: 'Environment Sync',
    icon: 'compare_arrows',
    type: 'collapse',
    url: '/sync',
    children: [
      {
        id: 'path-mappings-management',
        auth: ['syncManagement'],
        title: 'Path / Attribute Mappings',
        messageId: 'Path / Attribute Mappings',
        type: 'item',
        route: (
          <Route key="omp-page-route-/sync/path-mappings" path="/sync/path-mappings" element={<MappingSyncPage />} />
        ),
        url: '/sync/path-mappings',
      },
      {
        id: 'contact-flow-sync-management',
        auth: ['syncManagement'],
        title: 'Contact Flow Sync',
        messageId: 'Contact Flow Sync',
        type: 'item',
        route: (
          <Route
            key="omp-page-route-/sync/contact-flows"
            path="/sync/contact-flows"
            element={<ContactFlowSyncPage />}
          />
        ),
        url: '/sync/contact-flows',
      },
      {
        id: 'config-set-sync-management',
        auth: ['syncManagement'],
        title: 'Config Set Sync',
        messageId: 'Config Set Sync',
        type: 'item',
        route: (
          <Route key="omp-page-route-/sync/config-sets" path="/sync/config-sets" element={<ConfigSetSyncPage />} />
        ),
        url: '/sync/config-sets',
      },
    ],
  },
  {
    id: 'flow-engine-management',
    auth: ['flowEngineManagement'],
    title: 'Flow Engine',
    messageId: 'Flow Engine',
    icon: 'account_tree',
    type: 'collapse',
    url: '/contact-entry-points',
    children: [
      {
        id: 'contact-entry-points',
        auth: ['flowEngineManagement', 'entryManagement'],
        title: 'Contact Entry Points',
        messageId: 'Contact Entry Points',
        type: 'item',
        url: '/contact-entry-points',
        icon: 'play_circle',
        route: (
          <Route
            key="omp-page-route-/contact-entry-points"
            path="/contact-entry-points"
            element={<ContactEntryPointManagementPage />}
          />
        ),
      },
      {
        id: 'menu-management',
        auth: ['flowEngineManagement', 'entryManagement'],
        title: 'Menu Management',
        messageId: 'Menu Management',
        type: 'item',
        url: '/menu-management',
        icon: 'dialpad',
        route: <Route key="omp-page-route-/menu-management" path="/menu-management" element={<MenuManagementPage />} />,
      },
      {
        id: 'queue-treatment',
        auth: ['flowEngineManagement', 'queueTreatmentManagement'],
        title: 'Queue Treatment',
        messageId: 'Queue Treatment',
        type: 'item',
        url: '/queue-treatment',
        icon: 'perm_phone_msg',
        route: <Route key="omp-page-route-/queue-treatment" path="/queue-treatment" element={<QueueTreatmentPage />} />,
      },
    ],
  },
  {
    id: 'contact-entry-points-entry-view',
    auth: ['flowEngineManagement', 'entryManagement'],
    title: 'Contact Entry Points - Entry Point View',
    messageId: 'Contact Entry Points - Entry Point View',
    type: 'page',
    url: '/contact-entry-points/:phoneNumber',
    route: (
      <Route
        key="omp-page-route-/contact-entry-points"
        path="/contact-entry-points/:phoneNumber"
        element={<ContactEntryPointManagementPage />}
      />
    ),
  },
  {
    id: 'menu-management-menu-view',
    auth: ['flowEngineManagement', 'entryManagement'],
    title: 'Menu Management - Menu View',
    messageId: 'Menu Management - Menu View',
    type: 'page',
    url: '/menu-management/:menuId',
    route: (
      <Route key="omp-page-route-/menu-management" path="/menu-management/:menuId" element={<MenuManagementPage />} />
    ),
  },
  {
    id: 'permissions-management',
    auth: ['permissionsManagement', 'userManagement'],
    title: 'App Permissions Management',
    messageId: 'User Permissions Management',
    type: 'item',
    icon: 'people',
    url: '/user-management/permissions',
    route: (
      <Route
        key="omp-page-route-/user-management/permissions"
        path="/user-management/permissions/*"
        element={<PermissionsManagementPage />}
      />
    ),
  },
  {
    id: 'connect-user-management',
    auth: ['connectUserManagement'],
    title: 'Connect User Management',
    messageId: 'Connect User Management',
    type: 'item',
    url: '/connect/user-management',
    icon: 'contacts',
    route: (
      <Route
        key="omp-page-route-/connect/user-management"
        path="/connect/user-management/*"
        element={<UserManagementPage />}
      />
    ),
  },
  {
    id: 'realtime-metrics',
    auth: ['metrics', 'realTimeMetricsQueues', 'realTimeMetricsRoutingProfiles', 'realTimeMetricsAgents'],
    title: 'Realtime Metrics',
    messageId: 'Realtime Metrics',
    icon: 'assessment',
    type: 'collapse',
    url: '/connect/metrics/realtime-metrics',
    children: [
      {
        id: 'realtime-metrics-stacked',
        auth: ['metrics', 'realTimeMetricsQueues', 'realTimeMetricsRoutingProfiles', 'realTimeMetricsAgents'],
        title: 'Stacked Metrics',
        messageId: 'All Metrics',
        type: 'item',
        url: '/connect/metrics/realtime-metrics/all-metrics',
      },
      {
        id: 'realtime-metrics-queues',
        auth: ['metrics', 'realTimeMetricsQueues'],
        title: 'Queues',
        messageId: 'Queues',
        type: 'item',
        url: '/connect/metrics/realtime-metrics/queues',
      },
      {
        id: 'realtime-metrics-routingprofiles',
        auth: ['metrics', 'realTimeMetricsRoutingProfiles'],
        title: 'Routing Profiles',
        messageId: 'Routing Profiles',
        type: 'item',
        url: '/connect/metrics/realtime-metrics/routingprofiles',
      },
      {
        id: 'realtime-metrics-agents',
        auth: ['metrics', 'realTimeMetricsAgents'],
        title: 'Agents',
        messageId: 'Agents',
        type: 'item',
        url: '/connect/metrics/realtime-metrics/agents',
      },
    ],
    route: (
      <Route
        key="omp-page-route-/connect/metrics/realtime-metrics"
        path="/connect/metrics/realtime-metrics/*"
        element={<RealtimeMetricsPage />}
      />
    ),
  },
  {
    id: 'contact-search',
    auth: ['metrics', 'contactSearch'],
    title: 'Contact Search',
    messageId: 'Contact Search',
    type: 'item',
    url: '/connect/contact-search',
    icon: 'search',
    route: (
      <Route
        key="omp-page-route-/connect/contact-search"
        path="/connect/contact-search/*"
        element={<ContactSearchPage />}
      />
    ),
  },
  {
    id: 'phone-number-taxonomy',
    auth: ['phoneNumberTaxonomy'],
    title: 'Phone Number Taxonomy',
    messageId: 'Phone Number Taxonomy',
    type: 'item',
    url: '/phone-number-taxonomy',
    icon: 'phone',
    route: (
      <Route
        key="omp-page-route-/phone-number-taxonomy"
        path="/phone-number-taxonomy/*"
        element={<PhoneNumberTaxonomyPage />}
      />
    ),
  },
  {
    id: 'extensionDialing',
    auth: ['extensionDialing'],
    title: 'Extension Dialing',
    messageId: 'Extension Dialing',
    type: 'item',
    url: '/extension-dialing',
    icon: 'contact_phone',
    route: (
      <Route key="omp-page-route-/extension-dialing" path="/extension-dialing/*" element={<ExtensionDialingPage />} />
    ),
  },
];
