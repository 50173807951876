import { ApiClientBase } from './ApiClientBase';
import { IEntryPointManagementApi, EntryFlowData } from '@voicefoundry-cloud/vf-omp-shared';
import { getLogger } from '@vf/services/LoggingService';

export class EntryPointManagementApiClient extends ApiClientBase implements IEntryPointManagementApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('EntryPointManagementApiClient'), useAuth);
  }

  async listEntryPointData(): Promise<EntryFlowData[]> {
    const result = await this.get(`/api/entrypoint-management`);
    return await result.json();
  }

  async getEntryPointData(key: string): Promise<EntryFlowData> {
    const result = await this.get(`/api/entrypoint-management/${key}`);
    return await result.json();
  }
  async updateEntryPointData(data: EntryFlowData): Promise<EntryFlowData> {
    const result = await this.put(`/api/entrypoint-management/${data.key}`, data);
    return await result.json();
  }
  async createEntryPointData(data: EntryFlowData): Promise<EntryFlowData> {
    const result = await this.post(`/api/entrypoint-management/${data.key}`, data);
    return await result.json();
  }
  async deleteEntryPointData(key: string): Promise<void> {
    await this.delete(`/api/entrypoint-management/${key}`);
  }
}
