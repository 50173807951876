import React from 'react';
import { HashRouter } from 'react-router-dom';
import AuthRoutes from '@vf/utility/AuthRoutes';
import ContextProvider from '@vf/utility/ContextProvider';
import CssBaseline from '@mui/material/CssBaseline';
import InfoViewContextProvider from './shared/components/InfoView/InfoViewContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import VFStyleProvider from '@vf/utility/VFStyleProvider';
import ApiContextProvider from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { UserManagementContextProvider, TenantContextProvider } from 'contexts';
import InfoView from 'shared/components/InfoView';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { vfThemeOptions } from '@vf/theme/vfThemeOptions';

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={vfThemeOptions()}>
        <ContextProvider>
          <ApiContextProvider>
            <InfoViewContextProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <VFStyleProvider>
                  <HashRouter>
                    <InfoView />
                    <TenantContextProvider>
                      <UserManagementContextProvider>
                        <AuthRoutes>
                          <CssBaseline />
                        </AuthRoutes>
                      </UserManagementContextProvider>
                    </TenantContextProvider>
                  </HashRouter>
                </VFStyleProvider>
              </LocalizationProvider>
            </InfoViewContextProvider>
          </ApiContextProvider>
        </ContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
