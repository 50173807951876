import { getLogger } from '@vf/services/LoggingService';
import {
  OMPGetIntentData,
  OMPListBotsData,
  StandardApiReturn,
  OMPAssociateBotBody,
  OMPDisassociateBotBody,
} from '@voicefoundry-cloud/vf-omp-shared';
import { ApiClientBase } from './ApiClientBase';
import { BotSummary, DescribeBotCommandOutput, DescribeBotAliasCommandOutput } from '@aws-sdk/client-lex-models-v2';
import { AssociateBotCommandOutput, DisassociateBotCommandOutput } from '@aws-sdk/client-connect';

const lexResource = '/api/lex';
export class LexApiClient extends ApiClientBase {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('ConnectApiClient'), useAuth);
  }

  async getConnectBots(query: string): Promise<StandardApiReturn<OMPListBotsData>> {
    const result = await this.get(`${lexResource}/connect-bots${query || ''}`, 15000);
    return await result.json();
  }

  async getLexBots(): Promise<StandardApiReturn<BotSummary[]>> {
    const result = await this.get(`${lexResource}/bots`);
    return await result.json();
  }

  async describeBot(botId: string): Promise<StandardApiReturn<DescribeBotCommandOutput>> {
    const result = await this.get(`${lexResource}/bots/${botId}`);
    return await result.json();
  }

  async getIntents(botId: string, query: string): Promise<StandardApiReturn<OMPGetIntentData>> {
    const result = await this.get(`${lexResource}/intents/${botId}${query || ''}`);
    return await result.json();
  }

  async describeBotAlias(botId: string, botAliasId: string): Promise<StandardApiReturn<DescribeBotAliasCommandOutput>> {
    const result = await this.get(`${lexResource}/${botId}/${botAliasId}`);
    return await result.json();
  }

  async associateBot(body: OMPAssociateBotBody): Promise<StandardApiReturn<AssociateBotCommandOutput>> {
    const result = await this.put(`${lexResource}/associate-bot`, body);
    return await result.json();
  }

  async disassociateBot(body: OMPDisassociateBotBody): Promise<StandardApiReturn<DisassociateBotCommandOutput>> {
    const result = await this.post(`${lexResource}/disassociate-bot`, body);
    return await result.json();
  }
}
