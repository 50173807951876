import { createContext, useContext, useEffect, useReducer, ReactNode, useState } from 'react';
import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import { ContactFlowSummary } from 'aws-sdk/clients/connect';
import {
  QueueFlowData,
  Menu,
  OpenCheckTypes,
  QueueFlowActionTypes,
  QueueFlowTypes,
  Prompt,
} from '@voicefoundry-cloud/vf-omp-shared';
import { initialState, initialUpserHelpersState, initialWorkingQueueTreatment } from './QueueTreatmentConfig';
import { queueTreatmentReducer } from './queueTreatmentReducer';
import { IMessage } from 'types/models/IMessage';
import {
  getDeleteFailedMessage,
  getDeleteSuccessMessage,
  getDeletingMessage,
  getLoadingMessage,
  getLoadSuccessMessage,
  getSavingMessage,
  getUpdateFailedMessage,
  getUpdateSuccessMessage,
} from 'shared/constants/CRUD_Messages';

export enum ENTITY_TYPES {
  FLOWS = 'flows',
  CUSTOMER_QUEUE_FLOWS = 'customerQueueFlows',
  QUEUES = 'queues',
  PROMPTS = 'prompts',
}

const useQueueTreatment = () => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(queueTreatmentReducer, initialState);
  const [upsertHelpers, setUpsertHelpers] = useState(initialUpserHelpersState);
  const blankPollyPrompt = { value: '', lang: '' };
  const [workingPollyLanguagePrompt, setWorkingPollyLanguagePrompt] = useState<Prompt>(blankPollyPrompt);
  const entity = 'Queue Treatment';
  useEffect(() => {
    Promise.all([
      listQueueTreatments(),
      getQueues(),
      getFlows(),
      getAvailableHours(),
      listMenus(),
      getNumbers(),
      getQueueFlowMappings(),
      getConnectPrompts(),
    ]);
  }, [api]);

  useEffect(() => {
    if (state.numbersLoaded && state.queueTreatmentLoaded) {
      const availNumbers = state.numbers.filter(n => !state.queueTreatmentList.map(n => n.key).includes(n.PhoneNumber));
      dispatch({
        type: 'SET_AVAILABLE_NUMBERS',
        payload: availNumbers,
      });
    }
  }, [state.numbers, state.numbersLoaded, state.queueTreatmentList, state.queueTreatmentLoaded]);

  useEffect(() => {
    if (state.availableHoursLoaded) {
      const customHours = state.availableHours.filter(n => n.openCheckType === OpenCheckTypes.CUSTOM);
      dispatch({
        type: 'SET_AVAILABLE_CUSTOM_HOURS',
        payload: customHours,
      });
    }
  }, [state.availableHours, state.availableHoursLoaded]);
  useEffect(() => {
    if (state.flowsLoaded) {
      const customerQueueFlows = state.flows.filter(
        (flow: ContactFlowSummary) => flow.ContactFlowType === 'CUSTOMER_QUEUE'
      );
      dispatch({
        type: 'SET_CUSTOMER_QUEUE_FLOWS',
        payload: customerQueueFlows,
      });
    }
  }, [state.flowsLoaded]);

  useEffect(() => {
    if (!state.queueFlowMapperLoaded) {
      return;
    }
    const action = async () => {
      try {
        const prompts = await api.helpers.getAllPrompts();
        dispatch({
          type: 'GET_PROMPTS',
          payload: prompts,
        });
      } catch (err) {
        console.warn('Unable to load prompts', err);
      }
    };
    action();
  }, [state.queueFlowMapperLoaded]);

  const setWorkingQueueTreatment = (queueTreatment: QueueFlowData) => {
    dispatch({
      type: 'SET_WORKING_QUEUE_TREATMENT',
      payload: { queueTreatment: { ...initialWorkingQueueTreatment, ...queueTreatment } }, // spreading in model queue treatment for backwards compatibility
    });
  };

  const resetWorkingQueueTreatment = () => {
    setWorkingQueueTreatment(initialWorkingQueueTreatment);
  };

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };
  const getEntityAttrValue = (
    type: ENTITY_TYPES,
    keyType: string,
    keyValue: string,
    returnAttr: string = null
  ): any => {
    const item = state[type].find(o => o[keyType] === keyValue);
    if (!item) return '';
    return returnAttr ? item[returnAttr] : item;
  };

  const listMenus = async () => {
    dispatch({ type: 'LOADING_MENUS', payload: true });
    const menuList = await api.menu.listMenus();
    dispatch({ type: 'LIST_MENUS', payload: menuList });
  };

  const listQueueTreatments = async () => {
    setUpsertHelpers(prev => ({ ...prev, openBackdrop: true, loadingMessage: getLoadingMessage(`${entity}s`) }));
    try {
      const queueTreatmentList = await api.queueTreatment.listQueueFlowData();
      dispatch({
        type: 'LIST_QUEUE_TREATMENT',
        payload: queueTreatmentList,
      });
      setUpsertHelpers(prev => ({ ...prev, openBackdrop: false, successMessage: getLoadSuccessMessage(`${entity}s`) }));
    } catch (error) {
      console.warn(`Failed to load Queue Treatments:\n${JSON.stringify(error)}`);
      setUpsertHelpers(prev => ({ ...prev, openBackdrop: false, errorMessage: getLoadingMessage(`${entity}s`) }));
    }
    setUpsertHelpers(prev => ({ ...prev, openBackdrop: false, loadingMessage: '' }));
  };
  const getQueueFlowMappings = async () => {
    dispatch({
      type: 'LOADING_QUEUE_FLOW_MAPPER',
      payload: true,
    });
    const queueFlowMapper = await api.queueTreatment.getQueueFlowMapping().catch(err => []);
    dispatch({
      type: 'SET_QUEUE_FLOW_MAPPER',
      payload: queueFlowMapper,
    });
  };
  const createMenuScaffolding = async (menu: Menu) => {
    await api.menu.createMenu(menu);
    await listMenus();
  };

  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: numbers,
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };

  const getConnectPrompts = async () => {
    try {
      const connectPrompts = await api.helpers.getAllPrompts();
      dispatch({ type: 'GET_PROMPTS', payload: connectPrompts });
    } catch (error) {
      console.warn('Unable to load Connect prompts', error);
    }
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: queues,
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getAvailableHours = async () => {
    try {
      const availableHours = await api.hour.getAvailableHoursSelections();
      dispatch({
        type: 'GET_AVAILABLE_HOURS',
        payload: availableHours,
      });
    } catch (err) {
      console.warn('Unable to load available hours', err);
    }
  };
  const getFlows = async () => {
    try {
      const flows = await api.helpers.getAllFlows();
      dispatch({
        type: 'GET_FLOWS',
        payload: flows,
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getQueueTreatment = async (queueTreatmentName: string) => {
    const workingQueueTreatment = await api.queueTreatment.getQueueFlowData(queueTreatmentName);
    dispatch({
      type: 'GET_QUEUE_TREATMENT',
      payload: workingQueueTreatment,
    });
    return workingQueueTreatment;
  };
  const deleteQueueTreatment = async (queueTreatmentName: string) => {
    setUpsertHelpers(prev => ({
      ...prev,
      openBackdrop: true,
      loadingMessage: getDeletingMessage(queueTreatmentName, true),
    }));
    try {
      await api.queueTreatment.deleteQueueFlowData(queueTreatmentName);
      await listQueueTreatments();
      setUpsertHelpers(prev => ({ ...prev, successMessage: getDeleteSuccessMessage(queueTreatmentName, true) }));
    } catch (err) {
      setUpsertHelpers(prev => ({ ...prev, errorMessage: getDeleteFailedMessage(queueTreatmentName, true) }));
      return false;
    }
    setUpsertHelpers(prev => ({ ...prev, openBackdrop: false, loadingMessage: '' }));
    return true;
  };
  const upsertQueueTreatment = async (queueTreatment: QueueFlowData) => {
    const entity = 'Queue Treatment';
    setUpsertHelpers(prev => ({ ...prev, openBackdrop: true, loadingMessage: getSavingMessage(entity) }));
    try {
      const updated = await api.queueTreatment.updateQueueFlowData(queueTreatment);
      dispatch({ type: 'UPSERT_QUEUE_TREATMENT', payload: updated });
      setUpsertHelpers(prev => ({ ...prev, openBackdrop: false, successMessage: getUpdateSuccessMessage(entity) }));
    } catch (error) {
      console.warn(`Error upserting queue treatment:\n${error}`);
      setUpsertHelpers(prev => ({ ...prev, openBackdrop: false, errorMessage: getUpdateFailedMessage(entity) }));
    }

    setUpsertHelpers(prev => ({ ...prev, openBackdrop: false, loadingMessage: '' }));
  };

  const createQueueTreatmentScaffolding = async (queueTreatment: QueueFlowData) => {
    await api.queueTreatment.createQueueFlowData(queueTreatment);
    await listQueueTreatments();
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: msg });
  };

  const getQueueFlowMapping = (
    interruptSetting: number,
    holdMusic: string,
    actionType: QueueFlowActionTypes,
    id: boolean = false
  ) => {
    if (!state.queueFlowMapper || !state.queueFlowMapper.default || !interruptSetting || !holdMusic || !actionType)
      return '';
    if (actionType === QueueFlowActionTypes.BASIC_HOLD) {
      const map = state.queueFlowMapper.default[QueueFlowTypes.BASIC_HOLD];
      return id ? map[holdMusic].split('/')[3] : map[holdMusic];
    } else {
      const map = state.queueFlowMapper.default[QueueFlowTypes.INTERRUPT_ACTION];
      return id
        ? map[`${interruptSetting}_SECONDS`][holdMusic].split('/')[3]
        : map[`${interruptSetting}_SECONDS`][holdMusic];
    }
  };

  return {
    ...state,
    upsertHelpers,
    workingPollyLanguagePrompt,
    defaultQueueTreatment: initialWorkingQueueTreatment,
    setWorkingPollyLanguagePrompt,
    setUpsertHelpers,
    resetWorkingQueueTreatment,
    setWorkingQueueTreatment,
    getQueueMetadata,
    getEntityAttrValue,
    listQueueTreatments,
    createMenuScaffolding,
    getQueueTreatment,
    deleteQueueTreatment,
    upsertQueueTreatment,
    createQueueTreatmentScaffolding,
    setMsg,
    getQueueFlowMapping,
    getQueues,
    getFlows,
    getAvailableHours,
    listMenus,
    getNumbers,
    getQueueFlowMappings,
  };
};

export type UseQueueTreatmentType = ReturnType<typeof useQueueTreatment>;
// Context
export const QueueTreatmentContext = createContext<UseQueueTreatmentType | null>(null);
export const useQueueTreatmentContext = () => useContext(QueueTreatmentContext)!; // <<< don't forget the '!'

export const QueueTreatmentContextProvider = ({ children }: { children: ReactNode }) => (
  <QueueTreatmentContext.Provider value={useQueueTreatment()}>{children}</QueueTreatmentContext.Provider>
);
