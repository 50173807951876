import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';
import {
  AppHierarchyGroup,
  UMPolicyItem,
  UMRoleItem,
  UMRolePolicyItem,
  UMRoleUserItem,
} from '@voicefoundry-cloud/vf-omp-shared';
import config from './config';
import UserManagementContext from './ManagementContext';
import UserManagementContextPropsType, { UMIUserWithEmail } from './ManagementContextPropTypes';
import { reducer } from './reducer';
import { Logger } from 'aws-amplify';
import { merge, remove } from 'lodash';
import { TenantContext } from 'contexts';
import TenantContextPropTypes from 'contexts/tenant/ContextPropTypes';
import {
  useInfoViewActionsContext,
  fetchStart,
  fetchSuccess,
  fetchError,
} from 'shared/components/InfoView/InfoViewContext';
import { HierarchyGroup } from 'aws-sdk/clients/connect';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '@vf/utility/ContextProvider';

export const INITIAL_STATE = {
  ...config,
};

const logger = new Logger('PermissionsContextProvider', 'INFO');
const getHierarchyNumber = (number: string): number => {
  switch (number) {
    case 'LevelOne':
      return 1;
    case 'LevelTwo':
      return 2;
    case 'LevelThree':
      return 3;
    case 'LevelFour':
      return 4;
    case 'LevelFive':
      return 5;
    default:
      throw new Error(`Hierarchy level ${number} does not exist`);
  }
};
const UserManagementContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  logger.debug(`Initializing Provider`);
  const infoDispatch = useInfoViewActionsContext()!;
  const navigate = useNavigate();
  const { tenancyEnabled } = useAppContext();

  const { loadAdminTenantData } = useContext<TenantContextPropTypes>(TenantContext);

  useEffect(() => {
    if (tenancyEnabled) {
      loadAdminTenantData();
    }
  }, [loadAdminTenantData, tenancyEnabled]);

  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadUserManagementData = useCallback(() => {
    Promise.all([fetchCognitoUsers(), fetchRoles()]).then(() => dispatch({ type: 'FETCHING_SUCCESS' }));
  }, []);
  const loadTenantManagementData = useCallback(() => {
    return Promise.all([fetchCognitoUsers()]);
  }, []);
  const loadPermissionsManagementData = useCallback(async () => {
    Promise.all([
      await fetchCognitoUsers(),
      await fetchRoles(),
      await fetchPolicies(),
      await fetchDefaultPolicy(),
    ]).then(() => infoDispatch(fetchSuccess('User Management & Permissions Data loaded')));
  }, []);

  const loadNewPolicyPermissionsData = useCallback(async () => {
    Promise.all([await fetchDefaultPolicy(), await fetchResourceKeysForPolicy()]).then(() =>
      infoDispatch(fetchSuccess('New Policy Data loaded'))
    );
  }, []);

  const loadPolicyDetailPermissionsData = useCallback(async (policyKey: string) => {
    Promise.all([await fetchPolicyDetail(policyKey), await fetchResourceKeysForPolicy()]).then(() =>
      infoDispatch(fetchSuccess(`${policyKey} Policy Detail Data loaded`))
    );
  }, []);

  const loadRoleDetail = useCallback((roleKey: string) => {
    Promise.all([fetchRoleDetail(roleKey), fetchCognitoUsers(), fetchPolicies()]).then(() =>
      infoDispatch(fetchSuccess('Role Detail loaded'))
    );
  }, []);

  const clearState = () => {
    dispatch({ type: 'CLEAR_STATE', payload: INITIAL_STATE });
  };
  const getLevelHierarchies = (
    hierarchies: HierarchyGroup[],
    level?: string,
    parentLevel?: string,
    parentId?: string
  ): AppHierarchyGroup => {
    if (!level) {
      return {
        Level1: hierarchies
          .filter(h => h.LevelId === '1')
          .map(hl => {
            return {
              ...hl,
              ...getLevelHierarchies(hierarchies, '2', '1', hl.Id),
            };
          }),
      };
    } else if (level === '2') {
      return {
        Level2: hierarchies
          .filter(h => h.LevelId === '2' && h.HierarchyPath.LevelOne.Id === parentId)
          .map(hl => {
            return {
              ...hl,
              ...getLevelHierarchies(hierarchies, '3', '2', hl.Id),
            };
          }),
      };
    } else if (level === '3') {
      return {
        Level3: hierarchies
          .filter(h => h.LevelId === '3' && h.HierarchyPath.LevelTwo.Id === parentId)
          .map(hl => {
            return {
              ...hl,
              ...getLevelHierarchies(hierarchies, '4', '3', hl.Id),
            };
          }),
      };
    } else if (level === '4') {
      return {
        Level4: hierarchies
          .filter(h => h.LevelId === '4' && h.HierarchyPath.LevelThree.Id === parentId)
          .map(hl => {
            return {
              ...hl,
              ...getLevelHierarchies(hierarchies, '5', '4', hl.Id),
            };
          }),
      };
    } else {
      return {
        Level5: hierarchies.filter(h => h.LevelId === '5' && h.HierarchyPath.LevelFour.Id === parentId),
      };
    }
  };
  const fetchResourceKeysForPolicy = async () => {
    infoDispatch(fetchStart());
    try {
      const userNames = (await api.userManagement.getUsers()).map(u => u.user.userName);
      const roleKeys = (await api.userManagement.getRoles()).map(u => u.role.key);
      const policyKeys = (await api.userManagement.getPolicies()).map(u => u.policy.key);
      const connectUsers = await api.connect.listUsers();
      const connectUserKeys = connectUsers.map(u => u.Username);
      const connectPrompts = await api.connect.listPrompts();
      const connectPromptKeys = connectPrompts.map(u => u.Name);
      const connectHierarchyGroups = await api.connect.listUserHierarchyGroupsSummary();

      const connectHierarchyGroupKeys = connectHierarchyGroups.map(u => u.Name);
      const connectQueues = await api.connect.listQueues();
      const connectQueueKeys = connectQueues.map(u => u.Name);
      const connectNumbers = await api.connect.listNumbers();
      const connectNumberKeys = connectNumbers.map(u => u.PhoneNumber);
      const connectRoutingProfiles = await api.connect.listRoutingProfiles();
      const connectRoutingProfileKeys = connectRoutingProfiles.map(u => u.Name);
      const connectSecurityProfiles = await api.connect.listSecurityProfiles();
      const connectSecurityProfileKeys = connectSecurityProfiles.map(u => u.Name);

      //const connectDetailedHierarchies = await api.connect.listUserHierarchyGroupsDetailed();
      const connectDetailedHierarchies = (
        await Promise.all(
          connectHierarchyGroups.map(async hg => {
            try {
              return await api.connect.getUserHierarchyDetail(hg.Id);
            } catch (err) {
              console.log(err);
              return null;
            }
          })
        )
      ).filter(hg => !!hg);
      const connectHierarchyStructure = getLevelHierarchies(connectDetailedHierarchies);

      const connectContactFlows = await api.connect.listContactFlows();

      dispatch({
        type: 'SET_POLICY_CONFIG_KEYS',
        payload: {
          userNames,
          roleKeys,
          policyKeys,
          policySupplementalLoaded: true,
          connectUserKeys,
          connectPromptKeys,
          connectPrompts,
          connectQueueKeys,
          connectContactFlows,
          connectQueues,
          connectNumberKeys,
          connectNumbers,
          connectRoutingProfileKeys,
          connectRoutingProfiles,
          connectSecurityProfileKeys,
          connectSecurityProfiles,
          connectHierarchyGroupKeys,
          connectHierarchyGroups,
          connectDetailedHierarchies,
          connectHierarchyStructure,
        },
      });
      return;
    } catch (err) {
      console.log('error', err);
    }
  };

  const getHierarchyDetailDisplay = (id: string): string[] => {
    if (!state.connectDetailedHierarchies) return;
    const hierarchy = state.connectDetailedHierarchies.find(h => h.Id === id);
    return hierarchy
      ? Object.keys(hierarchy.HierarchyPath)
          .sort((a, b) => {
            if (getHierarchyNumber(a) < getHierarchyNumber(b)) {
              return -1;
            } else {
              return 1;
            }
          })
          .map(hk => hierarchy.HierarchyPath[hk].Name)
      : [];
  };
  const fetchCognitoUsers = async () => {
    infoDispatch(fetchStart());
    try {
      let appUsers: UMIUserWithEmail[] = await api.userManagement.getUsers();
      appUsers = appUsers.map(u => {
        const emailAttr = u.user.attributes ? u.user.attributes.find(a => a.Name === 'email') : null;
        const email = emailAttr ? emailAttr.Value : 'No Email address configured';
        return {
          ...u,
          email,
        };
      });
      dispatch({ type: 'SET_COGNITO_USERS', payload: appUsers });
      return;
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };

  const fetchDefaultPolicy = async () => {
    infoDispatch(fetchStart());
    try {
      const defaultPolicy = await api.userManagement.getDefaultPolicy();
      logger.info('Default ', { defaultPolicy });
      dispatch({ type: 'SET_DEFAULT_POLICY', payload: defaultPolicy });
      return;
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchRoles = async () => {
    infoDispatch(fetchStart());
    try {
      const roles = await api.userManagement.getRoles();
      await Promise.all(roles.map(r => api.userManagement.getRoleDetail(r.role.key)));
      dispatch({ type: 'SET_ROLES', payload: roles });
      return;
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchRoleDetail = async (roleKey: string) => {
    infoDispatch(fetchStart());
    try {
      const role = await api.userManagement.getRoleDetail(roleKey);
      console.log('ROLE ', role);
      dispatch({ type: 'SET_ROLE_DETAIL', payload: role });
      return;
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchPolicyDetail = async (policyKey: string) => {
    infoDispatch(fetchStart());
    try {
      const defaultPolicy = await api.userManagement.getDefaultPolicy();
      const policyDetail = await api.userManagement.getPolicyDetail(policyKey);
      policyDetail.data = merge(defaultPolicy, policyDetail.data);
      dispatch({ type: 'SET_POLICY_DETAIL', payload: policyDetail });
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const removeRolePolicy = async (rolePolicy: UMRolePolicyItem): Promise<void> => {
    infoDispatch(fetchStart());
    try {
      logger.info('removing role policy', rolePolicy);

      const { ok, message } = await api.userManagement.deleteRolePolicy(rolePolicy);
      if (ok) {
        dispatch({
          type: 'SET_ROLE_DETAIL',
          payload: {
            ...state.roleDetail,
            policy: null,
          },
        });
        infoDispatch(fetchSuccess('Policy removed from role'));
      } else {
        throw new Error(message);
      }
    } catch (err) {
      logger.info(err);
      infoDispatch(fetchError(err as string));
    }
  };
  const removeRoleUsers = async (roleKey: string, roleUsers: UMRoleUserItem[]): Promise<void> => {
    infoDispatch(fetchStart());
    try {
      logger.info('removing role users', roleUsers);

      const results = await Promise.all(
        roleUsers.map(async roleUser => {
          const res = await api.userManagement.deleteUserRole(roleUser);
          return {
            ok: res.ok,
            roleUser,
          };
        })
      );
      logger.info('Users removed!', results);
      const removeNames = remove(results, res => res.ok).map(res => res.roleUser.id);
      if (results.length > 0) {
        infoDispatch(fetchError(`${results.length} Users could not be removed`));
      }
      const newUsers = state.roleDetail.users.filter(ru => !removeNames.includes(ru.id));
      dispatch({
        type: 'SET_ROLE_DETAIL',
        payload: {
          ...state.roleDetail,
          users: newUsers,
        },
      });

      infoDispatch(fetchSuccess('Users removed from role'));
    } catch (err) {
      logger.info(err);
      infoDispatch(fetchError(err as string));
    }
  };
  const upsertRolePolicy = async (rolePolicy: UMRolePolicyItem): Promise<{ ok: boolean }> => {
    infoDispatch(fetchStart());
    try {
      logger.info('uploading', rolePolicy);
      await api.userManagement.upsertRolePolicy(rolePolicy);
      const role = await api.userManagement.getRoleDetail(rolePolicy.key);
      dispatch({ type: 'SET_ROLE_DETAIL', payload: role });
      infoDispatch(fetchSuccess('Policy associated to role'));
      return {
        ok: true,
      };
    } catch (err) {
      infoDispatch(fetchError(err as string));
      return {
        ok: false,
      };
    }
  };
  const upsertRoleUsers = async (roleKey: string, roleUsers: UMRoleUserItem[]): Promise<void> => {
    infoDispatch(fetchStart());
    try {
      await Promise.all(roleUsers.map(async roleUser => await api.userManagement.upsertUserRole(roleUser)));
      return fetchRoleDetail(roleKey).then(() => infoDispatch(fetchSuccess()));
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchPolicies = async () => {
    infoDispatch(fetchStart());
    try {
      const policies = await api.userManagement.getPolicies();
      console.log('Policies ', policies);
      dispatch({ type: 'SET_POLICIES', payload: policies });
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };

  const upsertRole = async (role: UMRoleItem): Promise<void> => {
    infoDispatch(fetchStart());
    try {
      await api.userManagement.upsertRole(role);
      await fetchRoles();
      return;
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };

  const upsertPolicy = async (policy: UMPolicyItem): Promise<void> => {
    infoDispatch(fetchStart());
    try {
      const newPolicy = await api.userManagement.upsertPolicy(policy);
      if (newPolicy.ok) {
        infoDispatch(fetchSuccess(`${newPolicy.policy.key} created / updated`));
        return;
      }

      throw new Error(newPolicy.message);

      // return fetchPolicies().then(() => {
      //   return infoDispatch(fetchSuccess('Policy Created / Updated'));
      // })
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };

  const deletePolicy = async (policy: UMPolicyItem): Promise<void> => {
    infoDispatch(fetchStart());
    try {
      await api.userManagement.deletePolicy(policy);
      infoDispatch(fetchSuccess(`${policy.key} has been deleted`));
      setTimeout(() => {
        navigate('/user-management/permissions');
      }, 500);
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const deleteRole = async (role: UMRoleItem): Promise<void> => {
    infoDispatch(fetchStart());
    try {
      await api.userManagement.deleteRole(role);
      infoDispatch(fetchSuccess(`${role.key} has been deleted`));
      setTimeout(() => {
        navigate('/user-management/permissions');
      }, 500);
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  return (
    <UserManagementContext.Provider
      value={{
        ...state,
        loadUserManagementData,
        loadTenantManagementData,
        loadPermissionsManagementData,
        loadRoleDetail,
        removeRoleUsers,
        upsertRoleUsers,
        removeRolePolicy,
        upsertRolePolicy,
        loadNewPolicyPermissionsData,
        loadPolicyDetailPermissionsData,
        getHierarchyDetailDisplay,
        upsertPolicy,
        upsertRole,
        clearState,
        deletePolicy,
        deleteRole,
      }}>
      {children}
    </UserManagementContext.Provider>
  );
};

export const useUserManagementContext = () => useContext<UserManagementContextPropsType>(UserManagementContext);

export default UserManagementContextProvider;
