import { ApiClientBase } from './ApiClientBase';
import { IQueueTreatmentManagementApi, QueueFlowData, QueueFlowMapper } from '@voicefoundry-cloud/vf-omp-shared';
import { getLogger } from '@vf/services/LoggingService';

export class QueueTreatmentApiClient extends ApiClientBase implements IQueueTreatmentManagementApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('QueueTreatmentManagementApiClient'), useAuth);
  }

  async listQueueFlowData(): Promise<QueueFlowData[]> {
    const result = await this.get(`/api/queuetreatment-management`);
    return await result.json();
  }

  async getQueueFlowData(key: string): Promise<QueueFlowData> {
    const result = await this.get(`/api/queuetreatment-management/${key}`);
    return await result.json();
  }
  async updateQueueFlowData(data: QueueFlowData): Promise<QueueFlowData> {
    const result = await this.put(`/api/queuetreatment-management/${data.key}`, data);
    return await result.json();
  }
  async createQueueFlowData(data: QueueFlowData): Promise<QueueFlowData> {
    const result = await this.post(`/api/queuetreatment-management/${data.key}`, data);
    return await result.json();
  }
  async deleteQueueFlowData(key: string): Promise<void> {
    await this.delete(`/api/queuetreatment-management/${key}`);
  }
  async getQueueFlowMapping(): Promise<QueueFlowMapper> {
    const result = await this.get(`/api/queuetreatment-management/queueflow-mapping`);
    return result.json();
  }
}
