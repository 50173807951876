import { VFThemeOptions } from './types';
import { vfDefaultTheme } from '@voicefoundry-cloud/vf-storybook/dist/themes/vf-default-theme';
import { merge } from 'lodash';

/**
 * Implemented as a function to allow custom color params
 */
export const vfThemeOptions = (): VFThemeOptions => {
  const customTheme = {
    palette: {
      info: {
        main: '#0290B0',
        light: '#3c536d',
        lightdark: '#24313e',
      },
    },
  };
  // VF Default styling inherited from storybook
  return merge(vfDefaultTheme, customTheme);
};
