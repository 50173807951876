import { ApiClientBase } from './ApiClientBase';
import {
  CreateCategoryRequest,
  CreateCategoryResponse,
  CreatePhoneNumberRequest,
  CreatePhoneNumberResponse,
  DeleteCategoryRequest,
  DeleteCategoryResponse,
  DeletePhoneNumberRequest,
  DeletePhoneNumberResponse,
  GetAllPhoneNumbersResponse,
  PhoneNumberCategoryType,
  PhoneNumberType,
  PhTaxonomyApiEndpoints,
  PutDetailsRequest,
  PutDetailsResponse,
  UMIAppConfigData,
} from '@voicefoundry-cloud/vf-omp-shared';
import { getLogger } from '@vf/services/LoggingService';

export interface IPhoneNumberTaxonomyApiClient {
  getDetails: () => Promise<PhoneNumberType[]>;
  getCategories: () => Promise<PhoneNumberCategoryType[]>;
  putDetails: (params: PutDetailsRequest) => Promise<PutDetailsResponse | undefined>;
  createPhoneNumber: (params: CreatePhoneNumberRequest) => Promise<CreatePhoneNumberResponse | undefined>;
  deletePhoneNumber: (params: DeletePhoneNumberRequest) => Promise<DeletePhoneNumberResponse | undefined>;
  createCategory: (params: CreateCategoryRequest) => Promise<CreateCategoryResponse | undefined>;
  deleteCategory: (params: DeleteCategoryRequest) => Promise<DeleteCategoryResponse | undefined>;
}

export class PhoneNumberTaxonomyApiClient extends ApiClientBase implements IPhoneNumberTaxonomyApiClient {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('PhoneNumberTaxonomyApiClient'), useAuth);
  }

  async getAppConfig(): Promise<UMIAppConfigData> {
    const result = await this.get(`/api/user-management/app-config`);
    return await result.json();
  }

  async getDetails(): Promise<PhoneNumberType[]> {
    const _response = await this.get(PhTaxonomyApiEndpoints.details);
    const response = (await _response.json()) as GetAllPhoneNumbersResponse;
    return response.phoneNumbers;
  }

  async getCategories(): Promise<PhoneNumberCategoryType[]> {
    const _response = await this.get(PhTaxonomyApiEndpoints.categories);
    const response = await _response.json();
    return response.categories;
  }

  async putDetails(params: PutDetailsRequest): Promise<PutDetailsResponse | undefined> {
    const _response = await this.put(PhTaxonomyApiEndpoints.details, params);
    const response = await _response.json();
    return _response.ok ? (response as PutDetailsResponse) : undefined;
  }

  async createPhoneNumber(params: CreatePhoneNumberRequest): Promise<CreatePhoneNumberResponse | undefined> {
    const _response = await this.post(PhTaxonomyApiEndpoints.details, params);
    const response = await _response.json();
    return _response.ok ? (response as CreatePhoneNumberResponse) : undefined;
  }

  async deletePhoneNumber(params: DeletePhoneNumberRequest): Promise<DeletePhoneNumberResponse | undefined> {
    const _response = await this.delete(PhTaxonomyApiEndpoints.details + `/${params.phoneNumberId}`);
    const response = (await _response.json()) as DeletePhoneNumberResponse;
    return _response.ok ? response : undefined;
  }

  async deleteCategory(params: DeleteCategoryRequest): Promise<DeleteCategoryResponse | undefined> {
    const _response = await this.delete(PhTaxonomyApiEndpoints.categories + `/${params.category}`);
    const response = (await _response.json()) as DeleteCategoryResponse;
    return _response.ok ? response : undefined;
  }

  async createCategory(params: CreateCategoryRequest): Promise<CreateCategoryResponse | undefined> {
    const _response = await this.post(PhTaxonomyApiEndpoints.categories, params);
    const response = (await _response.json()) as CreateCategoryResponse;
    return _response.ok ? response : undefined;
  }
}
