import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

export interface DynamicPageProps {
  src: string;
}

const DynamicPage = (props: DynamicPageProps) => {
  const params = useParams();
  const navigate = useNavigate();

  const eventListener = (e: MessageEvent<{ url: string }>) => {
    navigate(e.data.url, { replace: true });
  };
  useEffect(() => {
    window.addEventListener('message', eventListener);
    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, []);

  return (
    <iframe
      style={{ width: '1px', minWidth: '100%', height: '1000px' }}
      src={props.src.replace('*', params['*'] ?? '')}
      scrolling="no"
      frameBorder="0"
    />
  );
};

export default DynamicPage;
