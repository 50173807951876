import { getLogger } from '@vf/services/LoggingService';
import React, { useContext, useReducer, createContext } from 'react';
import { contextReducer } from './InfoViewReducer';

const logger = getLogger('InfoViewContextProvider');

export interface InfoViewTypes {
  error: string;
  loading: boolean;
  message: string;
}

export const InfoViewState: InfoViewTypes = {
  loading: false,
  error: '',
  message: '',
};
export interface FetchStartAction {
  type: 'FETCH_STARTS';
  payload?: string;
}

export interface FetchSuccessAction {
  type: 'FETCH_SUCCESS';
  payload?: string;
}

export interface FetchErrorAction {
  type: 'SET_ERROR';
  payload: string;
}

export interface ShowMessageAction {
  type: 'SET_MESSAGE';
  payload: string;
}

export type InfoViewActionTypes = FetchErrorAction | ShowMessageAction | FetchStartAction | FetchSuccessAction;

export const fetchStart = (): InfoViewActionTypes => ({
  type: 'FETCH_STARTS',
});
export const fetchSuccess = (message?: string): InfoViewActionTypes => ({
  type: 'FETCH_SUCCESS',
  payload: message ? message : '',
});
export const fetchError = (error: string): InfoViewActionTypes => ({
  type: 'SET_ERROR',
  payload: error,
});
export const showMessage = (message: string): InfoViewActionTypes => ({
  type: 'SET_MESSAGE',
  payload: message,
});

const InfoViewContext = createContext<InfoViewTypes>(InfoViewState);
const InfoViewActionsContext = createContext<React.Dispatch<InfoViewActionTypes> | undefined>(undefined);

export const useInfoViewContext = () => useContext(InfoViewContext);
export const useInfoViewActionsContext = () => useContext(InfoViewActionsContext);

const InfoViewContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [state, dispatch] = useReducer(contextReducer, InfoViewState, () => InfoViewState);
  logger.debug(`Initializing Provider`);
  return (
    <InfoViewContext.Provider value={state}>
      <InfoViewActionsContext.Provider value={dispatch}>{children}</InfoViewActionsContext.Provider>
    </InfoViewContext.Provider>
  );
};

export default InfoViewContextProvider;
