import { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import config from './config';
import { IMessage } from '../../types/models/IMessage';
import { PromptSummary } from 'aws-sdk/clients/connect';
import { reducer } from './reducer';
import { HolidayVm } from '@voicefoundry-cloud/vf-omp-shared';
import { ENTITY_TYPES, getEntityAttrValueHelper } from 'contexts/SharedContextModels';
export const initialState = {
  ...config,
};
const useHolidays = () => {
  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [connectPrompts, setConnectPrompts] = useState<PromptSummary[]>([]);

  useEffect(() => {
    listHolidays();
    listHolidayPrompts();
    getQueues();
    getNumbers();
    getTimezones();
    getConnectPrompts();
  }, [api]);

  const getQueueMetadata = (queueId: string) => {
    const queue = state.queues.find(queue => queue.Id === queueId);
    return queue;
  };

  const getConnectPrompts = async () => {
    try {
      const prompts = await api.connect.listPrompts();
      setConnectPrompts(prompts);
    } catch (error) {
      console.log(error);
    }
  };

  const listHolidays = async () => {
    dispatch({ type: 'LOADING_HOLIDAYS', payload: true });
    try {
      const holidaysList: HolidayVm[] = await api.holiday.getAllHolidays();
      dispatch({ type: 'LIST', payload: { holidaysList } });
    } catch (err) {
      dispatch({ type: 'LOADING_HOLIDAYS', payload: false });
      setMsg({
        message: 'Server error while loading holidays data',
        type: 'error',
      });
    }
  };

  const listHolidayPrompts = async () => {
    dispatch({ type: 'LOADING_PROMPTS', payload: true });
    try {
      const prompts = await api.holiday.getHolidayPrompts();
      dispatch({
        type: 'LIST_PROMPTS',
        payload: { prompts },
      });
    } catch (err) {
      dispatch({ type: 'LOADING_PROMPTS', payload: false });
      setMsg({
        message: 'Server error while loading prompts data',
        type: 'error',
      });
    }
  };

  const getQueues = async () => {
    try {
      const queues = await api.helpers.getAllQueues();
      dispatch({
        type: 'GET_QUEUES',
        payload: {
          queues,
        },
      });
    } catch (err) {
      console.warn('Unable to load queues', err);
    }
  };
  const getNumbers = async () => {
    try {
      const numbers = await api.helpers.getAllNumbers();
      dispatch({
        type: 'GET_NUMBERS',
        payload: {
          numbers,
        },
      });
    } catch (err) {
      console.warn('Unable to load claimed numbers', err);
    }
  };
  const getTimezones = async () => {
    try {
      const timezones = await api.helpers.getAllTimezones();
      dispatch({
        type: 'GET_TIMEZONES',
        payload: {
          timezones,
        },
      });
    } catch (err) {
      console.warn('Unable to load timezones', err);
    }
  };

  const setMsg = (msg: IMessage) => {
    dispatch({ type: 'SET_MESSAGE', payload: { msg } });
  };

  const getEntityAttrValue = (type: ENTITY_TYPES, keyType: string, keyValue: string, returnAttr: string = null) =>
    getEntityAttrValueHelper(state, type, keyType, keyValue, returnAttr);

  return {
    ...state,
    connectPrompts,
    getQueueMetadata,
    setMsg,
    getEntityAttrValue,
    listHolidays,
    listHolidayPrompts,
  };
};

type UseHolidaysType = ReturnType<typeof useHolidays>;
// Context
export const HolidaysContext = createContext<UseHolidaysType | null>(null);
export const useHolidaysContext = () => useContext(HolidaysContext)!;

export const HolidaysManagementContextProvider = ({ children }: { children: ReactNode }) => (
  <HolidaysContext.Provider value={useHolidays()}>{children}</HolidaysContext.Provider>
);
