import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLogger } from '@vf/services/LoggingService';
import { VFAppLayout, VFHelpMenuProps } from '@voicefoundry-cloud/vf-storybook';
import { Auth } from 'aws-amplify';
import AppRoutes from 'modules/AppRoutes';
import RouteDefinition from 'modules/RouteDefinition';
import { useAppContext } from './ContextProvider';

const logger = getLogger('AuthRoutes');

interface AuthRoutesProps {
  children: ReactNode;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({ children }) => {
  logger.debug(`Initializing Provider`);
  const { user, config } = useAppContext();
  const navigate = useNavigate();
  const routeDef = RouteDefinition();

  const helpMenuProps: VFHelpMenuProps = {
    helpTopics: [
      { topicTitle: 'Release Notes', icon: 'info', path: 'help/release-notes' },
      { topicTitle: 'Lambda Function Reference', icon: 'code', path: 'help/lambda-reference' },
    ],
    buttonColor: '#fff',
    buttonSize: 'small',
    buttonShape: 'square',
  };

  const signOutButton = async function signOut(): Promise<void> {
    try {
      await Auth.signOut();
      navigate('/');
    } catch (error) {
      console.warn('error signing out: ', error);
    }
  };

  return (
    <>
      {children}
      <VFAppLayout
        AppRoutes={AppRoutes}
        helpMenuProps={helpMenuProps}
        routesDefinition={routeDef}
        useAuth={true}
        onLogout={signOutButton}
        user={user!}
        useDefaultHeaderChips={true}
        config={{ environment: config.environment }}
        footerVersion={config.version}
      />
    </>
  );
};

export default AuthRoutes;
