import { ContactFlowSummary, PhoneNumberSummary, PromptSummary } from 'aws-sdk/clients/connect';
import { IMessage } from 'types/models/IMessage';
import {
  ConnectQueue,
  HoursSelection,
  MenuList,
  EntryFlowData,
  QueueFlowData,
  OpenCheckTypes,
  EntryPointTypes,
} from '@voicefoundry-cloud/vf-omp-shared';

export const initialWorkingEntryPoint: EntryFlowData = {
  key: '',
  entryPointType: EntryPointTypes.DNIS,
  isEnabled: false,
  openCheckKey: '',
  openCheckType: OpenCheckTypes.QUEUE,
  defaultQueueId: '',
  defaultLang: null,
  useDefaultQueueTreatmentKey: false,
  defaultCustomerQueueFlowId: '',
  defaultDisconnectFlow: '',
  defaultHoldFlow: '',
  defaultWhisperFlow: '',
  defaultJourneyOverride: '',
  defaultQueueTreatmentKey: '',
  afterHoursMsg: undefined,
  errorMsg: [],
  invalidInputMsg: [],
  OPEN: undefined,
  CLOSED: undefined,
  HOLIDAY: undefined,
  CLOSURE: undefined,
};

/**
 * @todo: dont use any, -ib 7/29/2022
 */
const initialState: ContactEntryPointState = {
  entryPointList: [],
  workingEntryPoint: initialWorkingEntryPoint,
  entryPointsLoaded: false,
  loadingEntryPoints: false,
  deletingEntryPoint: false,
  listLoaded: false,
  queueTreatmentLoaded: false,
  loadingQueueTreatment: false,
  loadingAvailableHours: false,
  loadingQueueTreatments: false,
  queueTreatmentList: [],
  queues: [],
  queuesLoaded: false,
  flows: [],
  flowsLoaded: false,
  customerQueueFlows: [],
  availableHours: [],
  availableHoursLoaded: false,
  menuList: [],
  menusLoaded: false,
  numbers: [],
  availableNumbers: [],
  numbersLoaded: false,
  availableCustomHours: [],
  prompts: [],
  promptsLoaded: false,
  message: {
    message: '',
    type: 'success',
  },
};

export interface ContactEntryPointState {
  workingEntryPoint: EntryFlowData;
  entryPointList: EntryFlowData[];
  listLoaded: boolean;
  deletingEntryPoint: boolean;
  loadingEntryPoints: boolean;
  loadingAvailableHours: boolean;
  loadingQueueTreatments: boolean;
  queuesLoaded: boolean;
  entryPointsLoaded: boolean;
  availableHoursLoaded: boolean;
  message: IMessage;
  flowsLoaded: boolean;
  promptsLoaded: boolean;
  queues: ConnectQueue[];
  flows: ContactFlowSummary[];
  prompts: PromptSummary[];
  customerQueueFlows: ContactFlowSummary[];
  availableHours: HoursSelection[];
  menuList: MenuList;
  menusLoaded: boolean;
  numbers: PhoneNumberSummary[];
  availableNumbers: PhoneNumberSummary[];
  numbersLoaded: boolean;
  availableCustomHours: HoursSelection[];
  queueTreatmentLoaded: boolean;
  loadingQueueTreatment: boolean;
  queueTreatmentList: QueueFlowData[];
}

export default initialState;
