import { ApiContext } from '@vf/utility/ApiContextProvider/ApiContextProvider';
import React, { useCallback, useContext, useEffect, useReducer } from 'react';

import config from './config';
import MetricsContext from './Context';

import { reducer } from './reducer';
import { Logger } from 'aws-amplify';
import { isNil } from 'lodash';
import {
  useInfoViewActionsContext,
  fetchStart,
  fetchSuccess,
  fetchError,
} from 'shared/components/InfoView/InfoViewContext';
import moment, { utc } from 'moment';
import { IContactSearchQuery, IContactSearchQueryId } from '@voicefoundry-cloud/vf-omp-shared';
import { useLocation } from 'react-router-dom';

export const INITIAL_STATE = {
  ...config,
};
const sleep = (milliseconds: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
const logger = new Logger('MetricsContextProvider', 'INFO');

const MetricsContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const infoDispatch = useInfoViewActionsContext()!;
  const location = useLocation();

  useEffect(() => {
    logger.debug('Location Change: ', location);
  }, [location]);

  // useEffect(() => {
  //   if (tenancyEnabled) {
  //     loadAdminTenantData();
  //   }
  // }, [loadAdminTenantData, tenancyEnabled]);

  const api = useContext(ApiContext);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    if (location.pathname === '/connect/metrics/realtime-metrics/queues') {
      clearRTRoutingProfileMetricsInterval();
      clearRTAgentMetricsInterval();
    } else if (location.pathname === '/connect/metrics/realtime-metrics/agents') {
      clearRTQueueMetricsInterval();
      clearRTRoutingProfileMetricsInterval();
    } else if (location.pathname === '/connect/metrics/realtime-metrics/routingprofiles') {
      clearRTAgentMetricsInterval();
      clearRTQueueMetricsInterval();
    } else if (location.pathname === '/connect/contact-search') {
      clearRTAgentMetricsInterval();
      clearRTQueueMetricsInterval();
      clearRTRoutingProfileMetricsInterval();
    } else if (location.pathname === '/connect/metrics/realtime-metrics/all-metrics') {
      clearRTAgentMetricsInterval();
      clearRTQueueMetricsInterval();
      clearRTRoutingProfileMetricsInterval();
    } else {
      clearRTAgentMetricsInterval();
      clearRTQueueMetricsInterval();
      clearRTRoutingProfileMetricsInterval();
    }
  }, [location]);
  const loadRTQueueMetricsData = useCallback(() => {
    clearRTQueueMetricsInterval();
    if (
      location.pathname === '/connect/metrics/realtime-metrics/queues' ||
      location.pathname === '/connect/metrics/realtime-metrics/all-metrics'
    ) {
      Promise.all([
        fetchRTQueueMetricsData().then(() => infoDispatch(fetchSuccess('LOADED: Real Time Queue Metrics'))),
      ]);
    }
  }, [location]);
  const loadRTAgentMetricsData = useCallback(() => {
    clearRTAgentMetricsInterval();
    if (
      location.pathname === '/connect/metrics/realtime-metrics/agents' ||
      location.pathname === '/connect/metrics/realtime-metrics/all-metrics'
    ) {
      Promise.all([
        fetchRTAgentMetricsData().then(() => infoDispatch(fetchSuccess('LOADED: Real Time Agent Metrics'))),
      ]);
    }
  }, [location]);
  const loadRTRPMetricsData = useCallback(() => {
    clearRTRoutingProfileMetricsInterval();
    if (
      location.pathname === '/connect/metrics/realtime-metrics/routingprofiles' ||
      location.pathname === '/connect/metrics/realtime-metrics/all-metrics'
    ) {
      Promise.all([
        fetchRTRoutingProfileMetricsData().then(() =>
          infoDispatch(fetchSuccess('LOADED: Real Time Routing Profile Metrics'))
        ),
      ]);
    }
  }, [location]);
  const loadFilterData = useCallback(() => {
    if (location.pathname === '/connect/contact-search') {
      Promise.all([
        loadFilterDataOptions(),
        // .then(() =>
        //   //infoDispatch(fetchSuccess('LOADED: Filter options')),
        // ),
      ]);
    }
  }, [location]);

  const initialContactSearchQuery = useCallback(
    (query: IContactSearchQuery) => {
      if (location.pathname === '/connect/contact-search') {
        Promise.all([startContactSearchQuery(query).then(() => infoDispatch(fetchSuccess('LOADED: Contact Search')))]);
      }
    },
    [location]
  );
  const startContactDetailAndAttributesQuery = useCallback(
    (contactId: string) => {
      if (location.pathname.includes('/connect/contact-search/contact-trace-records/details/')) {
        Promise.all([startContactDetailQuery(contactId), startContactAttributesQuery(contactId)]).then(() =>
          infoDispatch(fetchSuccess('LOADED: Contact Detail Information'))
        );
      }
    },
    [location]
  );

  const filterNilMetrics = (data: any) => {
    return data.filter(d => {
      const keys = Object.keys(d);
      const viableKeys = keys.filter(k => !['id', 'name'].includes(k));
      return !viableKeys.every(k => isNil(d[k]) || d[k] === 0);
    });
  };
  const fetchRTQueueMetrics = async () => {
    const data = await api.connect.getCurrentQueueMetricData();
    const queueMetrics = filterNilMetrics(data.metrics);
    dispatch({
      type: 'SET_RT_QUEUE_METRICS_DATA',
      payload: {
        metrics: queueMetrics,
        timestamp: utc(data.timestamp)
          // .utcOffset(data.timestamp)
          .local()
          .format('MMM D, YYYY h:mm:ss A'),
      },
    });
  };
  const fetchRTAgentMetrics = async () => {
    const data = await api.connect.getCurrentAgentMetricData();
    dispatch({
      type: 'SET_RT_AGENT_METRICS_DATA',
      payload: {
        metrics: data,
        timestamp: moment().format('MMM D, YYYY h:mm:ss A'),
      },
    });
  };
  const startRTQueueMetricsInterval = () => {
    const interval = setInterval(fetchRTQueueMetrics, 5000);
    dispatch({
      type: 'SET_RT_QUEUE_METRICS_INTERVAL',
      payload: {
        interval,
        on: true,
      },
    });
  };
  const startRTAgentMetricsInterval = () => {
    const interval = setInterval(fetchRTAgentMetrics, 5000);
    dispatch({
      type: 'SET_RT_AGENT_METRICS_INTERVAL',
      payload: {
        interval,
        on: true,
      },
    });
  };
  const fetchRTRoutingProfileMetrics = async () => {
    const data = await api.connect.getCurrentRoutingProfileMetricData();
    dispatch({
      type: 'SET_RT_RP_METRICS_DATA',
      payload: {
        ...data,
        timestamp: utc(data.timestamp).local().format('MMM D, YYYY h:mm:ss A'),
      },
    });
  };
  const startRTRoutingProfileMetricsInterval = () => {
    const interval = setInterval(fetchRTRoutingProfileMetrics, 5000);
    dispatch({
      type: 'SET_RT_RP_METRICS_INTERVAL',
      payload: {
        interval,
        on: true,
      },
    });
  };
  const loadFilterDataOptions = async () => {
    try {
      const agents = await api.connect.listUsersDetailed();
      const phoneNumbers = await api.connect.listNumbers();
      const queues = await api.connect.listQueues();
      dispatch({
        type: 'SET_AVAILABLE_CONNECT_USERS',
        payload: {
          availableConnectUsers: agents.map(u => ({
            Id: u.Id,
            Username: u.Username,
            FirstName: u.IdentityInfo.FirstName,
            LastName: u.IdentityInfo.LastName,
            search: `${u.Username},${u.IdentityInfo.Email ? u.IdentityInfo.Email : ''},${
              u.IdentityInfo.FirstName ? u.IdentityInfo.FirstName : ''
            },${u.IdentityInfo.LastName ? u.IdentityInfo.LastName : ''}`,
          })),
          availablePhoneNumbers: phoneNumbers.map(u => ({
            Id: u.Id,
            PhoneNumber: u.PhoneNumber,
            search: `${u.PhoneNumber}`,
          })),
          availableQueues: queues.map(q => ({
            Id: q.Id,
            Name: q.Name,
            Arn: q.Arn,
          })),
        },
      });
      return;
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchRTQueueMetricsData = async () => {
    infoDispatch(fetchStart());
    try {
      await fetchRTQueueMetrics();
      await startRTQueueMetricsInterval();
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchRTRoutingProfileMetricsData = async () => {
    infoDispatch(fetchStart());
    try {
      await fetchRTRoutingProfileMetrics();
      await startRTRoutingProfileMetricsInterval();
      return;
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const fetchRTAgentMetricsData = async () => {
    infoDispatch(fetchStart());
    try {
      await fetchRTAgentMetrics();
      await startRTAgentMetricsInterval();
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const clearRTQueueMetricsInterval = () => {
    window.clearInterval(state.rtQueueMetricsInterval);
    dispatch({
      type: 'SET_RT_QUEUE_METRICS_INTERVAL',
      payload: {
        interval: null,
        on: false,
      },
    });
  };
  const clearRTAgentMetricsInterval = () => {
    window.clearInterval(state.rtAgentMetricsInterval);
    dispatch({
      type: 'SET_RT_AGENT_METRICS_INTERVAL',
      payload: {
        interval: null,
        on: false,
      },
    });
  };
  const clearRTRoutingProfileMetricsInterval = () => {
    window.clearInterval(state.rtRoutingProfileMetricsInterval);
    dispatch({
      type: 'SET_RT_RP_METRICS_INTERVAL',
      payload: {
        interval: null,
        on: false,
      },
    });
  };

  const startContactSearchQuery = async (query: IContactSearchQuery): Promise<void> => {
    //infoDispatch(fetchStart())
    dispatch({ type: 'START_CONTACT_SEARCH_QUERY' });
    try {
      const response = await api.connect.startContactSearchQuery(query);
      if (response.ok) {
        const queryId = response.queryId;
        const results = await getContactSearchQueryResults(queryId);

        dispatch({
          type: 'SET_CONTACT_SEARCH_QUERY_RESULTS',
          payload: {
            results: results.results,
            contactSearchResultsAmt: results.totalNumRows,
          },
        });
        return;
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };
  const startContactDetailQuery = async (contactId): Promise<void> => {
    dispatch({ type: 'START_CONTACT_DETAIL_QUERY', payload: true });
    try {
      const response = await api.connect.startContactRecordDetailQuery(contactId);
      if (response.ok) {
        const queryId = response.queryId;
        const results = await getContactSearchQueryResults(queryId);
        dispatch({
          type: 'SET_CONTACT_DETAIL',
          payload: {
            results: results.results && results.results.length > 0 ? results.results[0] : {},
          },
        });
        return;
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
      dispatch({ type: 'START_CONTACT_DETAIL_QUERY', payload: false });
      infoDispatch(fetchError(err as string));
      return;
    }
  };
  const startContactAttributesQuery = async (contactId): Promise<void> => {
    dispatch({ type: 'START_CONTACT_ATTRIBUTES_QUERY', payload: true });
    try {
      const response = await api.connect.startContactAttributesQuery(contactId);
      if (response.ok) {
        const queryId = response.queryId;
        const results = await getContactSearchQueryResults(queryId);
        dispatch({
          type: 'SET_CONTACT_DETAIL_ATTRIBUTES',
          payload: {
            results: results.results,
          },
        });
        return;
      } else {
        throw new Error(response.message);
      }
    } catch (err) {
      dispatch({ type: 'SET_ERROR', payload: err });
      dispatch({ type: 'START_CONTACT_ATTRIBUTES_QUERY', payload: false });
      return;
    }
  };

  const getContactRecordDetail = (contactId: string): any => {
    return state.contactSearchResults.find(r => r.contact_id === contactId);
  };
  const getSignedUrlFromDetail = async (url: string): Promise<string> => {
    try {
      const res = await api.connect.getRecordingSignedUrl({ url: url });
      if (res.ok) {
        return res.url;
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      infoDispatch(fetchError(err as string));
    }
  };

  const getContactSearchQueryResults = async (queryId: IContactSearchQueryId, retries: number = 3) => {
    try {
      if (retries <= 3) {
        await sleep(1000);
        const response = await api.connect.getContactSearchResults(queryId);
        if (response.ready) {
          return response;
        } else {
          return await getContactSearchQueryResults(queryId);
        }
      } else {
        throw new Error('Server Error while accessing search results. Please try again');
      }
    } catch (err: any) {
      logger.error(err);
      if (retries !== 0) {
        return await getContactSearchQueryResults(queryId, retries - 1);
      } else {
        dispatch({ type: 'SET_ERROR', payload: err });
        dispatch({
          type: 'START_CONTACT_ATTRIBUTES_QUERY',
          payload: false,
        });
      }
    }
  };

  useEffect(() => {
    if (location.pathname === '/connect/contact-search') {
      if (state.runningContactSearchQuery) {
        infoDispatch(fetchStart());
      } else {
        if (state.error) {
          infoDispatch(fetchError(state.error as string));
        } else {
          infoDispatch(fetchSuccess('Retrieved Contact Search Results'));
        }
        dispatch({ type: 'SET_ERROR', payload: '' });
      }
    }
  }, [state.runningContactSearchQuery, location]);

  useEffect(() => {
    if (
      location.pathname !== '/' &&
      location.pathname.includes('/connect/contact-search/contact-trace-records/details/')
    ) {
      if (state.runningContactDetailQuery || state.runningContactAttributesQuery) {
        console.log('Starting spinner');
        infoDispatch(fetchStart());
      } else {
        infoDispatch(fetchSuccess('Retrieved Contact Trace Record and Attributes'));
      }
    }
  }, [state.runningContactDetailQuery, state.runningContactAttributesQuery, location]);

  return (
    <MetricsContext.Provider
      value={{
        ...state,
        loadRTQueueMetricsData,
        loadRTAgentMetricsData,
        loadRTRPMetricsData,
        fetchRTQueueMetrics,
        fetchRTAgentMetrics,
        fetchRTAgentMetricsData,
        fetchRTRoutingProfileMetrics,
        clearRTQueueMetricsInterval,
        startRTQueueMetricsInterval,
        clearRTAgentMetricsInterval,
        startRTAgentMetricsInterval,
        clearRTRoutingProfileMetricsInterval,
        startRTRoutingProfileMetricsInterval,
        loadFilterData,
        startContactSearchQuery,
        initialContactSearchQuery,
        getContactRecordDetail,
        getSignedUrlFromDetail,
        startContactDetailQuery,
        startContactAttributesQuery,
      }}>
      {children}
    </MetricsContext.Provider>
  );
};

export default MetricsContextProvider;
