import { ApiClientBase } from './ApiClientBase';
import {
  IUserManagementApi,
  UMDefaultPolicy,
  UMIAppConfigData,
  UMIPolicyDetail,
  UMIPolicyList,
  UMIRoleDetail,
  UMIRoleList,
  UMITenantList,
  UMIUserList,
  UMPolicyItem,
  UMRoleItem,
  UMRolePolicyItem,
  UMRoleUserItem,
  UMTenantItem,
  UMTenantUserItem,
} from '@voicefoundry-cloud/vf-omp-shared';
import { getLogger } from '@vf/services/LoggingService';

export class UserManagementApiClient extends ApiClientBase implements IUserManagementApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('UserManagementApiClient'), useAuth);
  }

  async getDefaultPolicy(): Promise<UMDefaultPolicy> {
    const result = await this.get(`/api/user-management/default-policy`);
    return await result.json();
  }
  async getAppConfig(): Promise<UMIAppConfigData> {
    const result = await this.get(`/api/user-management/app-config`);
    return await result.json();
  }

  async getUsers(): Promise<UMIUserList> {
    const result = await this.get(`/api/user-management/users`);
    return await result.json();
  }
  async getUserPrimaryTenant(): Promise<UMTenantItem> {
    const result = await this.get(`/api/user-management/primary-tenant`);
    return await result.json();
  }
  async upsertTenantUser(
    tenantUser: UMTenantUserItem
  ): Promise<{ ok: boolean; tenantUser: UMTenantUserItem; message?: string }> {
    const response = await this.post(`/api/user-management/tenant/${tenantUser.key}/user/${tenantUser.id}`, tenantUser);
    return response.json();
  }
  async deleteTenantUser(tenantUser: UMTenantUserItem): Promise<{ ok: boolean; message?: string }> {
    const response = await this.post(
      `/api/user-management/tenant/${tenantUser.key}/user/${tenantUser.id}/delete`,
      tenantUser
    );
    return response.json();
  }
  async getRoles(): Promise<UMIRoleList> {
    const result = await this.get(`/api/user-management/role`);
    return await result.json();
  }
  async getTenants(): Promise<UMITenantList> {
    const result = await this.get(`/api/user-management/tenant`);
    return await result.json();
  }
  async getUserTenants(userName: string): Promise<UMTenantItem[]> {
    const result = await this.get(`/api/user-management/users/${userName}/tenant`);
    return await result.json();
  }
  async getPolicies(): Promise<UMIPolicyList> {
    const result = await this.get(`/api/user-management/policy`);
    return await result.json();
  }

  async upsertUserRole(userRole: UMRoleUserItem): Promise<{ ok: boolean; userRole: UMRoleUserItem; message?: string }> {
    const result = await this.post(`/api/user-management/users/${userRole.id}/role/${userRole.key}`, userRole);
    return await result.json();
  }
  async upsertRolePolicy(
    rolePolicy: UMRolePolicyItem
  ): Promise<{ ok: boolean; rolePolicy: UMRolePolicyItem; message?: string }> {
    const result = await this.post(`/api/user-management/role/${rolePolicy.key}/policy/${rolePolicy.id}`, rolePolicy);
    return await result.json();
  }
  async deleteRole(role: UMRoleItem): Promise<{ ok: boolean; message?: string }> {
    console.log('ROLE ', role);
    const response = await this.post(`/api/user-management/role/${role.key}/delete`, role);
    return response.json();
  }
  async deleteRolePolicy(rolePolicy: UMRolePolicyItem): Promise<{ ok: boolean; message?: string }> {
    const result = await this.post(
      `/api/user-management/role/${rolePolicy.key}/policy/${rolePolicy.id}/delete`,
      rolePolicy
    );
    return await result.json();
  }
  async upsertRole(role: UMRoleItem): Promise<{ ok: boolean; role: UMRoleItem; message?: string }> {
    const result = await this.post(`/api/user-management/role`, role);
    return await result.json();
  }

  async deleteUserRole(userRole: UMRoleUserItem): Promise<{ ok: boolean; message?: string }> {
    const result = await this.post(`/api/user-management/users/${userRole.id}/role/${userRole.key}/delete`, userRole);
    return result.json();
  }
  async getRoleDetail(roleKey: string): Promise<UMIRoleDetail> {
    const result = await this.get(`/api/user-management/role/${roleKey}`);
    return await result.json();
  }
  async deletePolicy(policy: UMPolicyItem): Promise<{ ok: boolean; message?: string }> {
    const result = await this.post(`/api/user-management/policy/${policy.key}/delete`, policy);
    return await result.json();
  }
  async getPolicyDetail(policyKey: string): Promise<UMIPolicyDetail> {
    const result = await this.get(`/api/user-management/policy/${policyKey}/detail`);
    return await result.json();
  }
  async getPolicy(policyKey: string): Promise<UMPolicyItem> {
    const result = await this.get(`/api/user-management/policy/${policyKey}`);
    return await result.json();
  }
  async upsertPolicy(policy: UMPolicyItem): Promise<{ ok: boolean; policy: UMPolicyItem; message?: string }> {
    const result = await this.post(`/api/user-management/policy`, policy);
    return await result.json();
  }
}
