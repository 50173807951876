import { ApiClientBase } from './ApiClientBase';
import { getLogger } from '@vf/services/LoggingService';
import {
  ISyncApi,
  IMappingSyncData,
  IMappingSyncDataItem,
  IMappingSyncFlows,
  IConfigSetSyncResponse,
  SyncInitializationResponse,
  SyncReplaceSelectedMatchesResponse,
  SyncConfigSetDataList,
  IMappingSyncLambdaArns,
  ContactFlowExportParams,
  ContactFlowExportResponse,
  ExistingContactFlowSyncInitializationRequest,
  ExistingContactFlowSyncInitializationResponse,
  ContactFlowImportResponse,
  ImportContactFlowSyncInitializationRequest,
  SyncContactFlowMatchingReplaceRequest,
  SyncContactFlowMatchingReplaceResponse,
  ContactFlowImportRequest,
  ExistingConfigSetSyncInitializationRequest,
  ExistingConfigSetSyncInitializationResponse,
  SyncConfigSetMatchingReplaceRequest,
  SyncConfigSetMatchingReplaceResponse,
  ConfigSetImportRequest,
  ConfigSetImportResponse,
} from '@voicefoundry-cloud/vf-omp-shared';

import { ContactFlowSummary } from 'aws-sdk/clients/connect';

export class SyncApiClient extends ApiClientBase implements ISyncApi {
  constructor(apiUrl: string, useAuth: boolean) {
    super(apiUrl, getLogger('SyncApiClient'), useAuth);
  }

  async getFlowIds(): Promise<IMappingSyncFlows> {
    const result = await this.get(`/api/sync/flow-ids`);
    return await result.json();
  }
  async getQueueArns(): Promise<IMappingSyncDataItem> {
    const result = await this.get(`/api/sync/queue-arns`);
    return await result.json();
  }
  async getMappingSyncData(): Promise<IMappingSyncData> {
    const result = await this.get(`/api/sync/mappings-sync-data`);
    return await result.json();
  }
  async exportLocalPathData(): Promise<IMappingSyncData> {
    const result = await this.get(`/api/sync/export-local-mappings-sync-data`);
    return await result.json();
  }
  async importMappedPathData(mapping: IMappingSyncData): Promise<IMappingSyncData> {
    const result = await this.post(`/api/sync/import-mappings-sync-data`, mapping);
    return await result.json();
  }
  async getMappingConfig(): Promise<IMappingSyncData> {
    const result = await this.get(`/api/sync/mapping`);
    return await result.json();
  }
  async createMappingConfig(mapping: IMappingSyncData): Promise<IMappingSyncData> {
    const result = await this.post(`/api/sync/mapping`, mapping);
    return await result.json();
  }
  async updateMappingConfig(mapping: IMappingSyncData): Promise<IMappingSyncData> {
    const result = await this.put(`/api/sync/mapping`, mapping);
    return await result.json();
  }
  async deleteMappingConfig(): Promise<void> {
    await this.delete(`/api/sync/mapping`);
  }
  async syncConfigMappingData(): Promise<IConfigSetSyncResponse> {
    const result = await this.post(`/api/sync/config-data-run-sync`);

    return result.json();
  }
  async syncContactFlowsData(): Promise<{ res: any }> {
    const result = await this.post(`/api/sync/contact-flows-run-sync`);

    return result.json();
  }

  async initializeContactFlowSync(
    params: ExistingContactFlowSyncInitializationRequest
  ): Promise<ExistingContactFlowSyncInitializationResponse> {
    const result = await this.post(`/api/sync/contact-flows/initialize-sync`, params);

    return result.json();
  }
  async initializeContactFlowImportSync(
    params: ImportContactFlowSyncInitializationRequest
  ): Promise<SyncInitializationResponse> {
    const result = await this.post(`/api/sync/contact-flows/initialize-import-sync`, params);

    return result.json();
  }
  async getAvailableContactFlows(): Promise<ContactFlowSummary[]> {
    const result = await this.get(`/api/sync/contact-flows/get-available-flows`);

    return result.json();
  }
  async replaceSelectedMatchesFromContactFlow(
    params: SyncContactFlowMatchingReplaceRequest
  ): Promise<SyncReplaceSelectedMatchesResponse> {
    const result = await this.post(`/api/sync/contact-flows/run-replace-sync`, params);

    return result.json();
  }
  async replaceSelectedMatchesFromImportedContactFlow(
    params: SyncContactFlowMatchingReplaceRequest
  ): Promise<SyncContactFlowMatchingReplaceResponse> {
    const result = await this.post(`/api/sync/contact-flows/run-replace-imported-sync`, params);

    return result.json();
  }
  async getAvailableConfigSets(): Promise<SyncConfigSetDataList> {
    const result = await this.get(`/api/sync/config-sets/get-available-config-sets`);

    return result.json();
  }
  async initializeConfigSetSync(
    params: ExistingConfigSetSyncInitializationRequest
  ): Promise<ExistingConfigSetSyncInitializationResponse> {
    const result = await this.post(`/api/sync/config-sets/initialize-sync`, params);

    return result.json();
  }
  async initializeConfigSetImportSync(
    params: ExistingConfigSetSyncInitializationRequest
  ): Promise<ExistingConfigSetSyncInitializationResponse> {
    const result = await this.post(`/api/sync/config-sets/initialize-import-sync`, params);

    return result.json();
  }
  async replaceSelectedMatchesFromConfigSet(
    params: SyncConfigSetMatchingReplaceRequest
  ): Promise<SyncConfigSetMatchingReplaceResponse> {
    const result = await this.post(`/api/sync/config-sets/run-replace-sync`, params);

    return result.json();
  }
  async replaceSelectedMatchesFromImportedConfigSet(
    params: SyncConfigSetMatchingReplaceRequest
  ): Promise<SyncConfigSetMatchingReplaceResponse> {
    const result = await this.post(`/api/sync/config-sets/run-replace-imported-sync`, params);

    return result.json();
  }
  async getAssociatedLambdaArns(): Promise<IMappingSyncLambdaArns> {
    const result = await this.get(`/api/sync/config-sets/get-associated-lambdas`);

    return result.json();
  }
  async exportContactFlows(params: ContactFlowExportParams): Promise<ContactFlowExportResponse> {
    const result = await this.post(`/api/sync/contact-flows/export`, params);

    return result.json();
  }
  async importContactFlows(params: ContactFlowImportRequest): Promise<ContactFlowImportResponse> {
    const result = await this.post(`/api/sync/contact-flows/import`, params);

    return result.json();
  }
  async importConfigSets(params: ConfigSetImportRequest): Promise<ConfigSetImportResponse> {
    const result = await this.post(`/api/sync/config-sets/import`, params);

    return result.json();
  }
}
