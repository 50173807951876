import { OpenCheckTypes } from '@voicefoundry-cloud/vf-omp-shared';
import { HoursManagementState } from './HoursManagementContextPropTypes';

type HoursManagementAction =
  | 'DELETE'
  | 'UPSERT'
  | 'GET'
  | 'LIST'
  | 'GET_QUEUES'
  | 'GET_NUMBERS'
  | 'GET_TIMEZONES'
  | 'LOADING_HOURS'
  | 'DELETING_HOURS'
  | 'SET_ERROR'
  | 'SET_MESSAGE'
  | 'ADD_ASSOCIATION_KEY'
  | 'REMOVE_ASSOCIATION_KEY';

export function hoursReducer(
  state: HoursManagementState,
  action: { type: HoursManagementAction; payload: any }
): HoursManagementState {
  switch (action.type) {
    case 'DELETE': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deletingHours: false,
      };
    }
    case 'DELETING_HOURS': {
      // @todo Potentially remove menu from list instead of listing again
      return {
        ...state,
        deletingMenu: action.payload,
      };
    }
    case 'UPSERT': {
      // @todo Potentially append menu in list instead of listing again
      return state;
    }
    case 'LOADING_HOURS': {
      return {
        ...state,
        loadingHours: action.payload,
      };
    }
    case 'LIST': {
      return {
        ...state,
        hoursLoaded: true,
        loadingHours: false,
        associationsObj: {
          [OpenCheckTypes.QUEUE]: [],
          [OpenCheckTypes.DNIS]: [],
          [OpenCheckTypes.CUSTOM]: [],
        },
        hoursList: action.payload.hoursList,
      };
    }
    case 'GET_QUEUES':
      return {
        ...state,
        queuesLoaded: true,
        queues: action.payload.queues,
      };
    case 'GET_NUMBERS':
      return {
        ...state,
        numbersLoaded: true,
        numbers: action.payload.numbers,
      };
    case 'GET_TIMEZONES':
      return {
        ...state,
        timezonesLoaded: true,
        timezones: action.payload.timezones,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload.error,
      };
    case 'SET_MESSAGE':
      return {
        ...state,
        msg: action.payload.msg,
      };
    case 'ADD_ASSOCIATION_KEY': {
      const idx = state.associationsObj[action.payload.type].indexOf(action.payload.key);
      if (idx !== -1)
        return {
          ...state,
        };
      state.associationsObj[action.payload.type].push(action.payload.key);
      return {
        ...state,
        associationsObj: {
          ...state.associationsObj,
          [action.payload.type]: state.associationsObj[action.payload.type],
        },
      };
    }
    case 'REMOVE_ASSOCIATION_KEY': {
      const idx = state.associationsObj[action.payload.type].indexOf(action.payload.key);
      if (idx === -1)
        return {
          ...state,
        };
      state.associationsObj[action.payload.type].splice(idx, 1);
      return {
        ...state,
        associationsObj: {
          ...state.associationsObj,
          [action.payload.type]: state.associationsObj[action.payload.type],
        },
      };
    }

    default:
      return state;
  }
}
