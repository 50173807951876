import { Menu, MenuActionType } from '@voicefoundry-cloud/vf-omp-shared';
import { random } from 'lodash';
import { LexMenuOptions, MenuStateType } from './MenuManagementContextPropTypes';

const initialWorkingMenu: Menu = {
  key: '',
  journeyOverride: '',
  terminatingKeypress: '',
  retryCount: 2,
  isEnabled: false,
  description: 'Give your menu a description',
  menuOptions: [],
  promptIntro: [],
  greeting: [],
  invalidInputMessage: [],
  menuType: 'SINGLE_DTMF',
  defaultMenuOption: {
    dtmfOption: 'Timeout',
    action: {
      flowId: '',
      menuActionType: MenuActionType.TRANSFER_TO_FLOW,
    },
  },
  repeatMenuOption: {
    dtmfOption: '',
    action: {
      menuKey: '',
      menuActionType: MenuActionType.REPEAT_MENU,
    },
  },
};

const initialMenuState: MenuStateType = {
  menuList: [],
  menusLoaded: false,
  loadingMenu: false,
  deletingMenu: false,
  loadingMenus: false,
  promptsLoaded: false,
  loadingQueueTreatment: false,
  queueTreatmentsLoaded: false,
  prompts: [],
  queueTreatmentList: [],
  workingMenu: initialWorkingMenu,
  workingConnectLexBot: {} as LexMenuOptions,
  sampleAttribute: 'testing',
  queues: [],
  queuesLoaded: false,
  flows: [],
  flowsLoaded: false,
  modules: [],
  modulesLoaded: false,
  customerQueueFlows: [],
  connectLexBots: [],
  allLexBotSummaries: [],
};

export const getInitialWorkingMenu = (): Menu => {
  const randomKey = `Custom Menu ${random(100, 999)}`;
  return {
    ...initialWorkingMenu,
    key: randomKey,
    journeyOverride: randomKey,
  };
};

export const getInitalMenuState = (): MenuStateType => {
  return {
    ...initialMenuState,
    workingMenu: getInitialWorkingMenu(),
  };
};
