import React from 'react';
import { create } from 'jss';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { getLogger } from '@vf/services/LoggingService';

const logger = getLogger('VFStyleProvider');

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins] });

const VFStyleProvider: React.FC<React.ReactNode> = props => {
  logger.debug(`Initializing Provider`);
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
};
export default VFStyleProvider;
